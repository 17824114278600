
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import ApiService from "@/core/services/ApiService";
import { computed, defineComponent, onMounted, ref } from "vue";
import CardOffice from "@/components/cards/CardOffice.vue";
import OfficesCU from "./OfficesCU.vue";
import Swal from "sweetalert2/dist/sweetalert2.min.js";
import { useStore } from "vuex";
import { hasPermission } from "@/core/helpers/functions";
export default defineComponent({
  name: "ManagementOffices",
  components: {
    CardOffice,
    OfficesCU,
  },
  setup() {
    const store = useStore();
    // eslint-disable-next-line @typescript-eslint/ban-types
    const offices = ref<Array<object>>([]);
    const typeEvent = ref("");
    const dialogVisible = ref(false);
    const element = ref({});
    const page = ref(1);
    const pageSize = ref(3);

    const getOffices = () => {
      ApiService.get("/api/offices").then(({ data }) => {
        offices.value = data;
      });
    };

    const newOffice = () => {
      typeEvent.value = "create";
      dialogVisible.value = true;
    };

    const editOffice = (item) => {
      element.value = item;
      typeEvent.value = "update";
      dialogVisible.value = true;
    };

    const deleteOffice = (id) => {
      Swal.fire({
        text: "Esta seguro que desea eliminar esta empresa",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Yes, delete it!",
        cancelButtonText: "No, keep it",
        customClass: {
          confirmButton: "btn fw-bold btn-light-danger",
          cancelButton: "btn fw-bold btn-light-primary",
        },
      }).then((result) => {
        if (result.isConfirmed) {
          ApiService.delete("/api/offices/" + id).then(() => {
            const i = offices.value.findIndex(
              (e: Record<string, any>) => e.id === id
            );
            offices.value.splice(i, 1);
          });
        }
      });
    };

    const successOperation = (response) => {
      const index = offices.value.findIndex(
        (el: Record<string, any>) => el.id === response.id
      );
      if (index >= 0) {
        offices.value[index] = response;
      } else {
        offices.value.push(response);
      }
      dialogVisible.value = false;
    };

    const resetModal = () => {
      typeEvent.value = "";
      element.value = {};
    };

    const handleSizeChange = (val) => {
      page.value = 1;
      pageSize.value = val;
    };

    const setPage = (val) => {
      page.value = val;
    };

    const officesList = computed(() => {
      if (store.getters.searchLocal === "") {
        return (
          offices.value.slice(
            pageSize.value * page.value - pageSize.value,
            pageSize.value * page.value
          ) || []
        );
      } else {
        const result = offices.value.filter((ele: Record<string, any>) =>
          ele.name
            .toLowerCase()
            .includes(store.getters.searchLocal.toLowerCase())
        );
        return (
          result.slice(
            pageSize.value * page.value - pageSize.value,
            pageSize.value * page.value
          ) || []
        );
      }
    });

    onMounted(() => {
      setCurrentPageBreadcrumbs("officesIndex", ["companies", "offices"], true);
      getOffices();
    });
    return {
      newOffice,
      editOffice,
      deleteOffice,
      successOperation,
      resetModal,
      handleSizeChange,
      setPage,
      offices,
      officesList,
      typeEvent,
      dialogVisible,
      element,
      page,
      pageSize,
      create_offices: ref(hasPermission("offices: create")),
    };
  },
});
