
import { defineComponent, ref, onMounted } from "vue";
import { ErrorMessage, Field, Form } from "vee-validate";
import * as Yup from "yup";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import { translate } from "@/core/helpers/functions";
import ApiService from "@/core/services/ApiService";
import { useRoute } from "vue-router";
import { useI18n } from "vue-i18n";
interface FormOffice {
  id?: number;
  companyID?: number;
  name: string;
  cif: string;
  address: string;
  fiscalAddress: string;
  email: string;
  website: string;
  phone: string;
  fax: string;
  status: string;
  logo: string;
}
export default defineComponent({
  name: "OfficesCU",
  components: {
    Field,
    Form,
    ErrorMessage,
  },
  props: {
    typeEvent: String,
    item: Object,
  },
  setup(props, { emit }) {
    const route = useRoute();
    const submitButton = ref<HTMLElement | null>(null);
    const title = ref("");
    let logo: any;
    const { t } = useI18n({ useScope: "global" });
    const form = ref<FormOffice>({
      name: "",
      cif: "",
      address: "",
      fiscalAddress: "",
      email: "",
      website: "",
      phone: "",
      fax: "",
      status: "",
      logo: "",
    });
    const inputId = ref({
      loading: false,
      options: [],
      list: [],
    });
    const inputCompany = ref({
      loading: false,
      options: [],
      list: [],
    });

    const getOffices = () => {
      ApiService.get("/api/offices").then(({ data }) => {
        inputId.value.list = data;
      });
    };
    const selectOffices = (query) => {
      if (query !== "") {
        inputId.value.loading = true;
        setTimeout(() => {
          inputId.value.loading = false;
          inputId.value.options = inputId.value.list.filter(
            (item: Record<string, any>) => {
              return item.name.toLowerCase().indexOf(query.toLowerCase()) > -1;
            }
          );
        }, 200);
      } else {
        inputId.value.options = [];
      }
    };

    const setOffice = (id) => {
      const item = inputId.value.list.find((item: Record<string, any>) => {
        return item.id === id;
      }) || {
        id: "",
        company_id: "",
        name: "",
        CIF: "",
        address: "",
        fiscal_address: "",
        email: "",
        website: "",
        phone: "",
        fax: "",
        status: "",
        logo: "",
      };
      form.value.name = item.name;
      form.value.companyID = Number(item.company_id);
      form.value.cif = item.CIF || "";
      form.value.address = item.address;
      form.value.fiscalAddress = item.fiscal_address;
      form.value.email = item.email;
      form.value.website = item.website;
      form.value.phone = item.phone;
      form.value.fax = item.fax;
      form.value.status = item.status;
      form.value.logo = item.logo;
    };

    const getCompanies = () => {
      ApiService.get("/api/companies").then(({ data }) => {
        inputCompany.value.list = data;
      });
    };
    const selectCompanies = (query) => {
      if (query !== "") {
        inputCompany.value.loading = true;
        setTimeout(() => {
          inputCompany.value.loading = false;
          inputCompany.value.options = inputCompany.value.list.filter(
            (item: Record<string, any>) => {
              return item.name.toLowerCase().indexOf(query.toLowerCase()) > -1;
            }
          );
        }, 200);
      } else {
        inputCompany.value.options = [];
      }
    };

    const previewImage = (event) => {
      const [file] = event.target.files;
      if (file) {
        form.value[event.target.name] = URL.createObjectURL(file);
        if (event.target.name === "logo") {
          logo = event.target.files[0];
        }
      }
    };

    const removeImage = (input) => {
      form.value[input] = "media/defaults/blank.png";
      if (input === "logo") {
        logo = "";
      }
    };

    onMounted(() => {
      const nameRoute = route.name;
      getCompanies();
      if (nameRoute === "officesCreate" || props.typeEvent === "create") {
        title.value = t("createNewOfficeGroup");
      } else if (
        nameRoute === "officesUpdate" ||
        props.typeEvent === "update"
      ) {
        title.value = "Actualizar oficina";
      }

      if (props.item && Object.keys(props.item).length > 0) {
        form.value.id = props.item.id;
        form.value.companyID = props.item.company_id;
        form.value.name = props.item.name;
        form.value.cif = "no cif";
        form.value.address = "no address";
        form.value.fiscalAddress = "";
        form.value.email = "noemail@email.es";
        form.value.website = "http://nowesite.com";
        form.value.phone = "";
        form.value.fax = "";
        form.value.status = "";
        form.value.logo = props.item.logo;
      }
      if (nameRoute === "officesUpdate") {
        getOffices();
      }

      if (!props.typeEvent) {
        const nav =
          nameRoute == "officesCreate" ? "officesCreate" : "officesUpdate";
        setCurrentPageBreadcrumbs(nav, ["companies", "offices"], false);
      }
    });

    const createOffice = () => {
      const formData = new FormData();
      formData.append(
        "company_id",
        form.value.companyID ? form.value.companyID.toString() : ""
      );
      formData.append("name", form.value.name);
      formData.append("logo", logo);
      ApiService.postAttach("/api/offices", formData)
        .then(({ data }) => {
          emit("success", data);
          submitButton.value?.removeAttribute("data-kt-indicator");
        })
        .catch(() => {
          submitButton.value?.removeAttribute("data-kt-indicator");
        });
    };

    const updateOffice = () => {
      const formData = new FormData();
      formData.append(
        "company_id",
        form.value.companyID ? form.value.companyID.toString() : ""
      );
      formData.append("name", form.value.name);
      formData.append("logo", logo);
      ApiService.postAttach(`/api/offices/update/${form.value.id}`, formData)
        .then(({ data }) => {
          emit("success", data);
          submitButton.value?.removeAttribute("data-kt-indicator");
        })
        .catch(() => {
          submitButton.value?.removeAttribute("data-kt-indicator");
        });
    };

    const voffices = Yup.object().shape({
      name: Yup.string().required(translate("rname")).label("Name"),
      companyID: Yup.string().required().label("Companies group"),
      address: Yup.string().required().label("Address"),
      email: Yup.string().required().email().label("Email"),
      website: Yup.string().url().nullable(),
    });

    const onSubmitOffice = () => {
      if (submitButton.value) {
        submitButton.value.setAttribute("data-kt-indicator", "on");
        if (form.value.id) {
          updateOffice();
        } else {
          createOffice();
        }
      }
    };

    return {
      route,
      title,
      submitButton,
      voffices,
      form,
      logo,
      inputId,
      inputCompany,
      onSubmitOffice,
      createOffice,
      updateOffice,
      getOffices,
      selectOffices,
      setOffice,
      getCompanies,
      selectCompanies,
      previewImage,
      removeImage,
    };
  },
});
