
import { hasPermission } from "@/core/helpers/functions";
import { defineComponent, ref } from "vue";
export default defineComponent({
  name: "cardOffice",
  props: {
    widgetClasses: String,
    color: String,
    item: Object
  },
  setup() {
    return {
      update_offices: ref(hasPermission("offices: update")),
      delete_offices: ref(hasPermission("offices: delete"))
    };
  }
});
